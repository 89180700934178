import "./about.css";
import React, { useState } from 'react';

export default function Home() {
    return (
        <div>
            <div className="HookField">
                <h1 className="AboutInfomationFieldOne">Texan Small Business</h1>
                <h1 className="AboutInfomationFieldTwo">a better way to save</h1>
                <h1 className="AboutInfomationFieldThree">Diversified income</h1>
                <h1 className="AboutInfomationFieldFour"> Fights Inflation</h1>
                <h1 className="AboutInfomationFieldFive"> Proven To build wealth</h1>
            </div>
        </div>
    );
}