import "./home.css";
import React, { useState } from 'react';

export default function Home() {
    return (
        <div>
            <div className="HookField">
                <h1 className="InfomationFieldOne">Uses Accurate Real-Time,<br />  Intraday &  Historical <br />Market Data</h1>
                <h1 className="InfomationFieldTwo">Trusted Trading Stratigies</h1>
                <h1 className="InfomationFieldThree">Time tested <br /> Artificial intelligence</h1>
                <h1 className="InfomationFieldFour"> Depended on by hundreds</h1>
                <h1 className="InfomationFieldFive"> Transparent Pricing</h1>
            </div>
        </div>
    );
}