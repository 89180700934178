import {BrowserRouter, Routes, Route, Link, NavLink}from "react-router-dom";
import Home from './pages/home/home_page';
import Login from './pages/login/login_page';
import About from './pages/about/about_page';

import './App.css';

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <header>
        <nav className="NavigationBar">
          <div className="LogoText">
            <h1>Zockem</h1>
          </div>

          <div className="Links">
            <Link to="/">Home</Link>
            <NavLink to="About" className="AboutLink">About</NavLink>
            <NavLink to="Login" className="LoginLink">Login</NavLink>
          </div>
        </nav>
      </header>
      
        <Routes>
          <Route index element={<Home />} />
          <Route path="Login" element={<Login />} />
          <Route path="About" element={<About />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}


